import React, { useState } from 'react';
import { Layout, Tooltip, Button } from 'antd';
import { CloseOutlined, MenuOutlined, DownOutlined } from '@ant-design/icons';

import { ReactComponent as EllinikiDimokratia } from 'assets/icons/logo.gov.svg';
import { ReactComponent as Ellada20Revert } from 'assets/icons/ellada_2_0.svg';
import { ReactComponent as Operator } from 'assets/icons/operator_icon.svg';
import bot_img from 'assets/icons/Final_Chatobot_RGB.SVG';
// import { loginUrl } from 'utils';

const { Header } = Layout;

const HeaderLanding = ({setShowBot, showBot}) => {
  const[ showDropdown, setShowDropdown]=useState(false);

  // const menuItems=  [
  //   {
  //     key: 1,
  //     label: 'ΠΡΟΓΡΑΜΜΑ Ι',
  //     children: [
  //       {
  //         key: 2,
  //         label: <a href="https://digitalsme.gov.gr/programma-i-psifiaka-ergaleia-mme/" target="_blank" rel="noreferrer">Γενικές Πληροφορίες</a>,
  //       },
  //       {
  //         key: 3,
  //         label: <a href="https://digitalsme.gov.gr/plirofories-gia-promitheftes-psifiaka-ergaleia-mme/" target="_blank" rel="noreferrer">Πληροφορίες για Προμηθευτές</a>,
  //       }
  //     ]
  //   },
  //   {
  //     key: 4,
  //     label: 'ΠΡΟΓΡΑΜΜΑ ΙΙ',
  //     children: [
  //       {
  //         key: 5,
  //         label: <a href="https://smart-readiness.grantthornton.gr/welcome/" target="_blank" rel="noreferrer">Γενικές Πληροφορίες</a>,
  //       },
  //       {
  //         key: 6,
  //         label: <a href={loginUrl} target="_blank" rel="noreferrer">Πλατφόρμα Δικαιούχων</a>,
  //       }
  //     ]
  //   }
  // ];

  return (
    <Header className="app-layout__header_landing">
      <div className="header-left">
        <EllinikiDimokratia className="app-layout__logo-gr-democracy" 
          onClick={() => (window.open('https://www.gov.gr/', '_blank'))}
        />
        {/* <DigitalTransformation className="app-layout__logo-gr-digital-transformation"/> */}
      </div>
      <div className="header-right">
      <div>
          <Ellada20Revert
              className="app-layout__logo-gr-2-0"
              onClick={() => (window.open('https://greece20.gov.gr/', '_blank'))}
          />
        </div>
        <div className='header-links mt-2'>
          {/* <span className="header-link"><a href="https://digitalsme.gov.gr/" target="_blank">Αρχική</a></span>
          <div className='header-link header-tooltip'>
            <Tooltip 
              className='ml-1' 
              placement="bottomRight" 
              title={<Menu className='header-link-menu-items'  theme={'#003375'} style={{ width: 256 }} mode="vertical" items={menuItems} />}
              >
                <a>
                  Προγράμματα
                </a>
              </Tooltip>
          </div>
          <span className="header-link"><a href="https://digitalsme.gov.gr/%ce%bd%ce%ad%ce%b1-%ce%b1%ce%bd%ce%b1%ce%ba%ce%bf%ce%b9%ce%bd%cf%8e%cf%83%ce%b5%ce%b9%cf%82/" target="_blank">Νέα & Ανακοινώσεις</a></span>
           */}
          <div className='header-tooltip-operator'>
            <Tooltip 
            className='mt-3 ml-1' 
            placement="bottomRight" 
            title={
                <div class="header-tooltip-operator-left">
                  <div class="header-tooltip-operator-left-span">
                    <span>
                      Αν οι απορίες σας δεν<br/>
                      απαντήθηκαν από τις<br/>
                      Πληροφορίες, 
                      μπορείτε να μας <br/>
                      ρωτήσετε μέσω email εδώ:<br/>
                      <a href="mail:smartreadiness@ktpae.gr">smartreadiness@ktpae.gr</a>
                      <div style={{marginTop: "30px"}}>ή να καλέσετε στο Κέντρο<br/>
                      Εξυπηρέτησης της δράσης (09:00 – <br/>
                      17:00) <a href="tel:2102153936">2102153936</a>.
                      </div>
                      <div style={{marginTop: "30px"}}>
                        ή να συνομιλήσετε με τον ψηφιακό βοηθό
                      </div>
                      <div>
                        <a onClick={()=>setShowBot(!showBot)}>
                          <img
                            // className='smart-icon'
                            src={bot_img}
                            alt="bot_img"
                            width={200} 
                            height={200} 
                          />
                        </a>
                      </div>
                    </span> 
                   {/* <span>
                      Αν οι απορίες σας δεν<br/>
                      απαντήθηκαν από τις<br/>
                      Πληροφορίες, μπορείτε να μας <br/>
                      καλέσετε στο Κέντρο <br/>                  
                      Εξυπηρέτησης της δράσης (09:00 – <br/>
                      17:00) <a href="tel:2131300766">2131300766</a> και στο <a href="tel:2131300945">2131300945</a>
            </span>*/}
                  </div>
                </div>
              }
            >
              <div className='operator'>
                <Operator className='operator-icon'/>
                <DownOutlined className='operator-downoutline'/>
              </div>
            </Tooltip>
          </div>
        </div>
        <div className='header-icon-mobile'>
            {!showDropdown && <MenuOutlined onClick={()=> setShowDropdown(true)}/>}
            {showDropdown && <CloseOutlined onClick={()=> setShowDropdown(false)}/>}
        </div>
        {showDropdown && <div className="header-links-mobile">
          {/* <span className="header-link"><a href="https://digitalsme.gov.gr/" target="_blank">Αρχική</a></span>
          <span className="header-link">
            <span>Προγράμματα</span>
            {menuItems.map((item, key) =>
                <ul key={key}>
                  <span>{item.label}</span>
                  {item.children.map((child, key) => 
                    <li key={key}>{child.label}</li>
                  )}
                </ul>)
            }
          </span>
          <span className="header-link"><a href="https://digitalsme.gov.gr/%ce%bd%ce%ad%ce%b1-%ce%b1%ce%bd%ce%b1%ce%ba%ce%bf%ce%b9%ce%bd%cf%8e%cf%83%ce%b5%ce%b9%cf%82/" target="_blank">Νέα & Ανακοινώσεις</a></span>
           */}
          <Operator className='operator-icon'/> 
          <div className="header-paragraph">
                   <p>Αν οι απορίες σας δεν απαντήθηκαν από τις Πληροφορίες, μπορείτε να μας ρωτήσετε μέσω email εδώ:&nbsp;<br/>
                   <a href="mail:smartreadiness@ktpae.gr">smartreadiness@ktpae.gr</a>
                   ή να καλέσετε στο Κέντρο<br/>
                      Εξυπηρέτησης της δράσης (09:00 – <br/>
                      17:00) <a href="tel:2102153936">2102153936</a>
                      <p style={{marginTop: "30px"}}>
                        ή να συνομιλήσετε με τον ψηφιακό βοηθό
                      </p>
                  </p>
                      
                    
          </div>
          <div className="header-paragraph">
              <a onClick={()=>setShowBot(!showBot)}>
                <img
                  // className='smart-icon'
                  src={bot_img}
                  alt="bot_img"
                  width={100} 
                  height={100} 
                />
              </a>
            </div>
        </div>}
      </div>
    </Header>
  );
};

export default HeaderLanding;
