import React from "react";

const TableCell = (props) =>{

    function renderItem(param){
        if(typeof param==="undefined" || param === null || param.length === 0  ){
            return <span>-</span>
        }else{
            return <span className={props.css}>{props.text} {props.symbol}</span>
        }
    }


    return(
        renderItem(props.text)
    )
}

export default TableCell;