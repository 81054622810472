import React from 'react';
import { loginUrl } from 'utils';
import { Layout, Button } from 'antd';

import { HeaderLanding } from 'components/layout/components';
import { ReactComponent as Group1149 } from 'assets/icons/group_1149.svg';
import { ReactComponent as Cycle1 } from 'assets/icons/cycle_icons_1.svg';
import { ReactComponent as Cycle2 } from 'assets/icons/cycle_icons_2.svg';
import { ReactComponent as Cycle3 } from 'assets/icons/cycle_icons_3.svg';
import { ReactComponent as Login } from 'assets/icons/login_button.svg';
import { ReactComponent as Ellada20Dark } from 'assets/icons/ellada_2_0_dark.svg';

const { Content } = Layout;

const LandingPage = () => {
  return (
    <Layout>
      <HeaderLanding className="header-landing" />
      <Content className="taa-banner">
        <div className="taa-content">
          <Group1149 className="taa-icon" />
          <div className="taa-paragraph">
          Υποβολή αιτήσεων χρηματοδότησης για την ένταξη στο Πρόγραμμα «Ανάπτυξη Ψηφιακών Προϊόντων και Υπηρεσιών» του Εθνικού Σχεδίου Ανάκαμψης και Ανθεκτικότητας Ελλάδα 2.0
          </div>
        </div>
      </Content>
      <Content className="welcome-banner">
        <div className="welcome-title">
        Καλωσήρθατε στην ηλεκτρονική πλατφόρμας υποβολής των αιτήσεων χρηματοδότησης του Πρoγράμματος «Ανάπτυξη Ψηφιακών Προϊόντων και Υπηρεσιών».
        </div>
        <div className="welcome-paragraph">
          <p>
          Η νέα πλατφόρμα αποτελεί την <b>κεντρική πύλη εισόδου</b> των ιδιωτικών επενδύσεων που θα αξιολογηθούν για να λάβουν ενίσχυση, υπό την μορφή επιχορηγήσεων, για την υλοποίηση επενδυτικών σχεδίων που καλύπτουν όλο το κύκλο ανάπτυξης νέων ψηφιακών προϊόντων και υπηρεσιών.
          </p>
          <p>
          Απευθύνεται προς τις ελληνικές επιχειρήσεις μικρού και μεσαίου μεγέθους, οι οποίες έχουν τη δυνατότητα να λάβουν ενίσχυση για τις δαπάνες της επένδυσής τους έως το 70% του κόστους για τις μικρές επιχειρήσεις και έως το 60% του κόστους για τις μεσαίες επιχειρήσεις. Τα έργα προς ενίσχυση μπορούν να έχουν προϋπολογισμό από <b>200.000,00€</b> ως <b>2.000.000,00€</b>, όπως ορίζεται στην Υπουργική Απόφαση.
          </p>
          <p>
          Οι επενδύσεις αυτές θα οδηγήσουν στην ανάπτυξη νέων προϊόντων και υπηρεσιών που εμπίπτουν στον κλάδο πληροφορικής και επικοινωνιών ενισχύοντας την <b>ψηφιακή παραγωγική ικανότητα</b> της χώρας και την <b>εξωστρέφεια των Ελληνικών Επιχειρήσεων.</b>
          </p>
          <p>
          Το Πρόγραμμα «Ανάπτυξη Ψηφιακών Προϊόντων και Υπηρεσιών» του Άξονα 2.3 «Ψηφιοποίηση των Επιχειρήσεων» αποτελεί μέρος της Δράσης 16706 «Ψηφιακός Μετασχηματισμός των Μικρομεσαίων Επιχειρήσεων», που υλοποιούνται στο πλαίσιο του Εθνικού Σχεδίου Ανάκαμψης και Ανθεκτικότητας Ελλάδα 2.0 με τη χρηματοδότηση της Ευρωπαϊκής Ένωσης – NextGenerationEU.
          </p>
        </div>
        <div>
          <Button
            className="login-button"
            onClick={() => (window.location = loginUrl)}
          >
            <span>Εισοδος στην εφαρμογή</span>
            <Login className="login-button-icon" />
          </Button>
        </div>
      </Content>
      <Content className="pylwnes">
        <div className="pylwnes-title">
        Κύκλοι “Ανάπτυξης Νέων Ψηφιακών Προϊόντων και Υπηρεσιών”
        </div>
        <div className="pylwnes-icons">
          <div className="first-row">
            <div className="contents-icons">
              <div>
                <Cycle1 className='cycle1'/>
              </div> 
              <p className='contents-icons-description'>Προπαρασκευαστικές <br></br>δραστηριότητες</p>           
            </div>
            <div className="contents-icons">
              <div>
                <Cycle2 className='cycle2'/>
              </div>
              <div className='contents-icons-description'>Δραστηριότητες Ανάπτυξης των νέων <br></br>ψηφιακών προϊόντων / υπηρεσιών</div> 
            </div>
            <div className="contents-icons">
              <div>
                <Cycle3 className='cycle3'/>
              </div>
              <div className='contents-icons-description'>Συμπληρωματικές δραστηριότητες
              <br></br>Εμπορικής αξιοποίησης των νέων
              <br></br>προϊόντων και υπηρεσιών</div> 
            </div>
          </div>
        </div>
      </Content>
      <Content className="landing-page-footer">
        <Ellada20Dark
          className="footer-logo"
          onClick={() => (window.location.href = 'https://greece20.gov.gr/')}
        />
        <p className="foooter-paragraph">
          Η δράση υλοποιείται στο πλαίσιο του Εθνικού Σχεδίου Ανάκαμψης και
          Ανθεκτικότητας Ελλάδα 2.0 <br /> με τη χρηματοδότηση της Ευρωπαικής
          Ένωσης – NextGenerationEU
        </p>
        <p>
          Copyright © 2022 - Υλοποίηση από την{' '}
          <a href="https://www.ktpae.gr/" target="_blank" rel="noreferrer">
          Κοινωνία της Πληροφορίας A.E.
          </a>
        </p>
      </Content>
    </Layout>
  );
};

export default LandingPage;
