import React, { useState, useRef } from 'react';
import { loginUrl , loginUrl2 } from 'utils';
import { Layout, Collapse, Table, Button , Select , Row, Col } from 'antd';
import { HeaderLanding, SubHeaderLanding, Footer } from 'components/layout/components';
import Iframe from 'react-iframe';
import { useNavigate } from 'react-router-dom';

const { Content } = Layout;

const MapSmart = () => {

const navigate = useNavigate();
const iframeRef = useRef(null);

window.onmessage = function(event) {
  console.log('event: ', event)
  if(event.data && event.data.action === "submit"){
    navigate('/egkatastates');
  }
  // navigate('/egkatastates');
}

  return (
    <Layout>
      <HeaderLanding className="header-landing" />
      {/* <SubHeaderLanding className="header-landing" /> */}
      {/* <Content className="badges"> */}
      <Content className="main-content" style={{marginTop: "4em"}}>
      <ul>
        <li><b>Στη μπάρα αναζήτησης «Εισάγετε διεύθυνση» συμπληρώστε τη διεύθυνση του κτιρίου (όλη ή μέρος αυτής) για το οποίο επιθυμείτε να ελέγξετε την επιλεξιμότητά του.</b></li>
        <br></br>
        <li><b>Μεταφέρεστε αυτόματα στο σημείο του χάρτη που πιθανόν βρίσκεται το κτίριο.</b></li>
        <br></br>
        <li><b>Τοποθετήστε την πινέζα ακριβώς στο σημείο που βρίσκεται το κτίριο, είτε υπάρχει κουκίδα είτε όχι.</b></li>
        <br></br>
        <li><b>Με την τοποθέτηση της πινέζας εμφανίζεται σχετικό μήνυμα που σας ενημερώνει για τη διεύθυνση του κτιρίου, καθώς και για το αν είναι επιλέξιμο για ένταξη στη δράση Smart Readiness.</b></li>
        <br></br>
        <br></br>
      </ul>
       <> 
        <Content className='documents-content'>
           <div>
                <div>
                <Iframe url="https://www.broadband-assist.gov.gr/public/eligible_public.php"
                  width="100%"
                  height="700px"
                  innerRef={iframeRef}
                  id="XEDM"
                  className=""
                  display="block"
                  position="relative"/>
                </div>
          </div>
        </Content>
      </> 
   
      </Content>
      <Content className='landing-page-footer-smart'>
        <Footer/>
      </Content>
    </Layout>
  );
};

export default MapSmart;
