export const treeData = [
{
    value: 'Άνδρου',
    title: 'Άνδρου',
    key: 'Άνδρου',
    id: '59',
    children: [
      {
        value: 'Άνδρου$Άνδρου',
        title: 'Άνδρου',
        key: 'Άνδρου$Άνδρου',
        id: '5901',
      },
    ]
},
{
    value: 'Άρτας',
    title: 'Άρτας',
    key: 'Άρτας',
    id: '19',
    children: [
      {
        value: 'Άρτας$Αρταίων',
        title: 'Αρταίων',
        key: 'Άρτας$Αρταίων',
        id: '1901',
      },
      {
        value: 'Άρτας$Νικόλαου Σκουφά',
        title: 'Νικόλαου Σκουφά',
        key: 'Άρτας$Νικόλαου Σκουφά',
        id: '1904',
      },
      {
        value: 'Άρτας$Γεωργίου Καραϊσκάκη',
        title: 'Γεωργίου Καραϊσκάκη',
        key: 'Άρτας$Γεωργίου Καραϊσκάκη',
        id: '1902',
      },
      {
        value: 'Άρτας$Κεντρικών Τζουμέρκων',
        title: 'Κεντρικών Τζουμέρκων',
        key: 'Άρτας$Κεντρικών Τζουμέρκων',
        id: '1903',
      },
    ]
},
{
    value: 'Έβρου',
    title: 'Έβρου',
    key: 'Έβρου',
    id: '03',
    children: [
      {
        value: 'Έβρου$Ορεστιάδας',
        title: 'Ορεστιάδας',
        key: 'Έβρου$Ορεστιάδας',
        id: '0303',
      },
      {
        value: 'Έβρου$Διδυμοτείχου',
        title: 'Διδυμοτείχου',
        key: 'Έβρου$Διδυμοτείχου',
        id: '0302',
      },
      {
        value: 'Έβρου$Σουφλίου',
        title: 'Σουφλίου',
        key: 'Έβρου$Σουφλίου',
        id: '0305',
      },
      {
        value: 'Έβρου$Αλεξανδρούπολης',
        title: 'Αλεξανδρούπολης',
        key: 'Έβρου$Αλεξανδρούπολης',
        id: '0301',
      },
      {
        value: 'Έβρου$Σαμοθράκης',
        title: 'Σαμοθράκης',
        key: 'Έβρου$Σαμοθράκης',
        id: '0304',
      },
    ]
},
{
    value: 'Αιτωλοακαρνανίας',
    title: 'Αιτωλοακαρνανίας',
    key: 'Αιτωλοακαρνανίας',
    id: '38',
    children: [
      {
        value: 'Αιτωλοακαρνανίας$Αμφιλοχίας',
        title: 'Αμφιλοχίας',
        key: 'Αιτωλοακαρνανίας$Αμφιλοχίας',
        id: '3804',
      },
      {
        value: 'Αιτωλοακαρνανίας$Ακτίου-Βόνιτσας',
        title: 'Ακτίου-Βόνιτσας',
        key: 'Αιτωλοακαρνανίας$Ακτίου-Βόνιτσας',
        id: '3802',
      },
      {
        value: 'Αιτωλοακαρνανίας$Ξηρομέρου',
        title: 'Ξηρομέρου',
        key: 'Αιτωλοακαρνανίας$Ξηρομέρου',
        id: '3807',
      },
      {
        value: 'Αιτωλοακαρνανίας$Ιεράς Πόλεως Μεσολογγίου',
        title: 'Ιεράς Πόλεως Μεσολογγίου',
        key: 'Αιτωλοακαρνανίας$Ιεράς Πόλεως Μεσολογγίου',
        id: '3801',
      },
      {
        value: 'Αιτωλοακαρνανίας$Αγρινίου',
        title: 'Αγρινίου',
        key: 'Αιτωλοακαρνανίας$Αγρινίου',
        id: '3803',
      },
      {
        value: 'Αιτωλοακαρνανίας$Θέρμου',
        title: 'Θέρμου',
        key: 'Αιτωλοακαρνανίας$Θέρμου',
        id: '3805',
      },
      {
        value: 'Αιτωλοακαρνανίας$Ναυπακτίας',
        title: 'Ναυπακτίας',
        key: 'Αιτωλοακαρνανίας$Ναυπακτίας',
        id: '3806',
      },
    ]
},
{
    value: 'Ανατολικής Αττικής',
    title: 'Ανατολικής Αττικής',
    key: 'Ανατολικής Αττικής',
    id: '49',
    children: [
      {
        value: 'Ανατολικής Αττικής$Βάρης - Βούλας - Βουλιαγμένης',
        title: 'Βάρης - Βούλας - Βουλιαγμένης',
        key: 'Ανατολικής Αττικής$Βάρης - Βούλας - Βουλιαγμένης',
        id: '4902',
      },
      {
        value: 'Ανατολικής Αττικής$Κρωπίας',
        title: 'Κρωπίας',
        key: 'Ανατολικής Αττικής$Κρωπίας',
        id: '4904',
      },
      {
        value: 'Ανατολικής Αττικής$Σαρωνικού',
        title: 'Σαρωνικού',
        key: 'Ανατολικής Αττικής$Σαρωνικού',
        id: '4911',
      },
      {
        value: 'Ανατολικής Αττικής$Λαυρεωτικής',
        title: 'Λαυρεωτικής',
        key: 'Ανατολικής Αττικής$Λαυρεωτικής',
        id: '4905',
      },
      {
        value: 'Ανατολικής Αττικής$Μαρκόπουλου Μεσογαίας',
        title: 'Μαρκόπουλου Μεσογαίας',
        key: 'Ανατολικής Αττικής$Μαρκόπουλου Μεσογαίας',
        id: '4907',
      },
      {
        value: 'Ανατολικής Αττικής$Παιανίας',
        title: 'Παιανίας',
        key: 'Ανατολικής Αττικής$Παιανίας',
        id: '4908',
      },
      {
        value: 'Ανατολικής Αττικής$Σπάτων-Αρτέμιδος',
        title: 'Σπάτων-Αρτέμιδος',
        key: 'Ανατολικής Αττικής$Σπάτων-Αρτέμιδος',
        id: '4912',
      },
      {
        value: 'Ανατολικής Αττικής$Παλλήνης',
        title: 'Παλλήνης',
        key: 'Ανατολικής Αττικής$Παλλήνης',
        id: '4909',
      },
      {
        value: 'Ανατολικής Αττικής$Ραφήνας-Πικερμίου',
        title: 'Ραφήνας-Πικερμίου',
        key: 'Ανατολικής Αττικής$Ραφήνας-Πικερμίου',
        id: '4910',
      },
      {
        value: 'Ανατολικής Αττικής$Μαραθώνος',
        title: 'Μαραθώνος',
        key: 'Ανατολικής Αττικής$Μαραθώνος',
        id: '4906',
      },
      {
        value: 'Ανατολικής Αττικής$Διονύσου',
        title: 'Διονύσου',
        key: 'Ανατολικής Αττικής$Διονύσου',
        id: '4903',
      },
      {
        value: 'Ανατολικής Αττικής$Ωρωπού',
        title: 'Ωρωπού',
        key: 'Ανατολικής Αττικής$Ωρωπού',
        id: '4913',
      },
      {
        value: 'Ανατολικής Αττικής$Αχαρνών',
        title: 'Αχαρνών',
        key: 'Ανατολικής Αττικής$Αχαρνών',
        id: '4901',
      },
    ]
},
{
    value: 'Αργολίδας',
    title: 'Αργολίδας',
    key: 'Αργολίδας',
    id: '41',
    children: [
      {
        value: 'Αργολίδας$Άργους-Μυκηνών',
        title: 'Άργους-Μυκηνών',
        key: 'Αργολίδας$Άργους-Μυκηνών',
        id: '4102',
      },
      {
        value: 'Αργολίδας$Ναυπλιέων',
        title: 'Ναυπλιέων',
        key: 'Αργολίδας$Ναυπλιέων',
        id: '4101',
      },
      {
        value: 'Αργολίδας$Επιδαύρου',
        title: 'Επιδαύρου',
        key: 'Αργολίδας$Επιδαύρου',
        id: '4103',
      },
      {
        value: 'Αργολίδας$Ερμιονίδας',
        title: 'Ερμιονίδας',
        key: 'Αργολίδας$Ερμιονίδας',
        id: '4104',
      },
    ]
},
{
    value: 'Αρκαδίας',
    title: 'Αρκαδίας',
    key: 'Αρκαδίας',
    id: '40',
    children: [
      {
        value: 'Αρκαδίας$Νότιας Κυνουρίας',
        title: 'Νότιας Κυνουρίας',
        key: 'Αρκαδίας$Νότιας Κυνουρίας',
        id: '4005',
      },
      {
        value: 'Αρκαδίας$Βόρειας Κυνουρίας',
        title: 'Βόρειας Κυνουρίας',
        key: 'Αρκαδίας$Βόρειας Κυνουρίας',
        id: '4002',
      },
      {
        value: 'Αρκαδίας$Τρίπολης',
        title: 'Τρίπολης',
        key: 'Αρκαδίας$Τρίπολης',
        id: '4001',
      },
      {
        value: 'Αρκαδίας$Γορτυνίας',
        title: 'Γορτυνίας',
        key: 'Αρκαδίας$Γορτυνίας',
        id: '4003',
      },
      {
        value: 'Αρκαδίας$Μεγαλόπολης',
        title: 'Μεγαλόπολης',
        key: 'Αρκαδίας$Μεγαλόπολης',
        id: '4004',
      },
    ]
},
{
    value: 'Αχαΐας',
    title: 'Αχαΐας',
    key: 'Αχαΐας',
    id: '37',
    children: [
      {
        value: 'Αχαΐας$Πατρέων',
        title: 'Πατρέων',
        key: 'Αχαΐας$Πατρέων',
        id: '3701',
      },
      {
        value: 'Αχαΐας$Αιγιαλείας',
        title: 'Αιγιαλείας',
        key: 'Αχαΐας$Αιγιαλείας',
        id: '3702',
      },
      {
        value: 'Αχαΐας$Καλαβρύτων',
        title: 'Καλαβρύτων',
        key: 'Αχαΐας$Καλαβρύτων',
        id: '3705',
      },
      {
        value: 'Αχαΐας$Ερυμάνθου',
        title: 'Ερυμάνθου',
        key: 'Αχαΐας$Ερυμάνθου',
        id: '3704',
      },
      {
        value: 'Αχαΐας$Δυτικής Αχαΐας',
        title: 'Δυτικής Αχαΐας',
        key: 'Αχαΐας$Δυτικής Αχαΐας',
        id: '3703',
      },
    ]
},
{
    value: 'Βοιωτίας',
    title: 'Βοιωτίας',
    key: 'Βοιωτίας',
    id: '28',
    children: [
      {
        value: 'Βοιωτίας$Τανάγρας',
        title: 'Τανάγρας',
        key: 'Βοιωτίας$Τανάγρας',
        id: '2806',
      },
      {
        value: 'Βοιωτίας$Θηβαίων',
        title: 'Θηβαίων',
        key: 'Βοιωτίας$Θηβαίων',
        id: '2804',
      },
      {
        value: 'Βοιωτίας$Αλιάρτου',
        title: 'Αλιάρτου',
        key: 'Βοιωτίας$Αλιάρτου',
        id: '2802',
      },
      {
        value: 'Βοιωτίας$Ορχομενού',
        title: 'Ορχομενού',
        key: 'Βοιωτίας$Ορχομενού',
        id: '2805',
      },
      {
        value: 'Βοιωτίας$Λεβαδέων',
        title: 'Λεβαδέων',
        key: 'Βοιωτίας$Λεβαδέων',
        id: '2801',
      },
      {
        value: 'Βοιωτίας$Διστόμου-Αράχοβας-Αντίκυρας',
        title: 'Διστόμου-Αράχοβας-Αντίκυρας',
        key: 'Βοιωτίας$Διστόμου-Αράχοβας-Αντίκυρας',
        id: '2803',
      },
    ]
},
{
    value: 'Βορείου Τομέα Αθηνών',
    title: 'Βορείου Τομέα Αθηνών',
    key: 'Βορείου Τομέα Αθηνών',
    id: '46',
    children: [
      {
        value: 'Βορείου Τομέα Αθηνών$Παπάγου-Χολαργού',
        title: 'Παπάγου-Χολαργού',
        key: 'Βορείου Τομέα Αθηνών$Παπάγου-Χολαργού',
        id: '4609',
      },
      {
        value: 'Βορείου Τομέα Αθηνών$Αγίας Παρασκευής',
        title: 'Αγίας Παρασκευής',
        key: 'Βορείου Τομέα Αθηνών$Αγίας Παρασκευής',
        id: '4602',
      },
      {
        value: 'Βορείου Τομέα Αθηνών$Χαλανδρίου',
        title: 'Χαλανδρίου',
        key: 'Βορείου Τομέα Αθηνών$Χαλανδρίου',
        id: '4612',
      },
      {
        value: 'Βορείου Τομέα Αθηνών$Βριλησσίων',
        title: 'Βριλησσίων',
        key: 'Βορείου Τομέα Αθηνών$Βριλησσίων',
        id: '4603',
      },
      {
        value: 'Βορείου Τομέα Αθηνών$Αμαρουσίου',
        title: 'Αμαρουσίου',
        key: 'Βορείου Τομέα Αθηνών$Αμαρουσίου',
        id: '4601',
      },
      {
        value: 'Βορείου Τομέα Αθηνών$Φιλοθέης-Ψυχικού',
        title: 'Φιλοθέης-Ψυχικού',
        key: 'Βορείου Τομέα Αθηνών$Φιλοθέης-Ψυχικού',
        id: '4611',
      },
      {
        value: 'Βορείου Τομέα Αθηνών$Νέας Ιωνίας',
        title: 'Νέας Ιωνίας',
        key: 'Βορείου Τομέα Αθηνών$Νέας Ιωνίας',
        id: '4608',
      },
      {
        value: 'Βορείου Τομέα Αθηνών$Μεταμορφώσεως',
        title: 'Μεταμορφώσεως',
        key: 'Βορείου Τομέα Αθηνών$Μεταμορφώσεως',
        id: '4607',
      },
      {
        value: 'Βορείου Τομέα Αθηνών$Ηρακλείου',
        title: 'Ηρακλείου',
        key: 'Βορείου Τομέα Αθηνών$Ηρακλείου',
        id: '4604',
      },
      {
        value: 'Βορείου Τομέα Αθηνών$Λυκόβρυσης-Πεύκης',
        title: 'Λυκόβρυσης-Πεύκης',
        key: 'Βορείου Τομέα Αθηνών$Λυκόβρυσης-Πεύκης',
        id: '4606',
      },
      {
        value: 'Βορείου Τομέα Αθηνών$Κηφισιάς',
        title: 'Κηφισιάς',
        key: 'Βορείου Τομέα Αθηνών$Κηφισιάς',
        id: '4605',
      },
      {
        value: 'Βορείου Τομέα Αθηνών$Πεντέλης',
        title: 'Πεντέλης',
        key: 'Βορείου Τομέα Αθηνών$Πεντέλης',
        id: '4610',
      },
    ]
},
{
    value: 'Γρεβενών',
    title: 'Γρεβενών',
    key: 'Γρεβενών',
    id: '15',
    children: [
      {
        value: 'Γρεβενών$Δεσκάτης',
        title: 'Δεσκάτης',
        key: 'Γρεβενών$Δεσκάτης',
        id: '1502',
      },
      {
        value: 'Γρεβενών$Γρεβενών',
        title: 'Γρεβενών',
        key: 'Γρεβενών$Γρεβενών',
        id: '1501',
      },
    ]
},
{
    value: 'Δράμας',
    title: 'Δράμας',
    key: 'Δράμας',
    id: '02',
    children: [
      {
        value: 'Δράμας$Δοξάτου',
        title: 'Δοξάτου',
        key: 'Δράμας$Δοξάτου',
        id: '0202',
      },
      {
        value: 'Δράμας$Παρανεστίου',
        title: 'Παρανεστίου',
        key: 'Δράμας$Παρανεστίου',
        id: '0204',
      },
      {
        value: 'Δράμας$Δράμας',
        title: 'Δράμας',
        key: 'Δράμας$Δράμας',
        id: '0201',
      },
      {
        value: 'Δράμας$Προσοτσάνης',
        title: 'Προσοτσάνης',
        key: 'Δράμας$Προσοτσάνης',
        id: '0205',
      },
      {
        value: 'Δράμας$Κάτω Νευροκοπίου',
        title: 'Κάτω Νευροκοπίου',
        key: 'Δράμας$Κάτω Νευροκοπίου',
        id: '0203',
      },
    ]
}, 
{
    value: 'Δυτικής Αττικής',
    title: 'Δυτικής Αττικής',
    key: 'Δυτικής Αττικής',
    id: '50',
    children: [
      {
        value: 'Δυτικής Αττικής$Φυλής',
        title: 'Φυλής',
        key: 'Δυτικής Αττικής$Φυλής',
        id: '5005',
      },
      {
        value: 'Δυτικής Αττικής$Ασπροπύργου',
        title: 'Ασπροπύργου',
        key: 'Δυτικής Αττικής$Ασπροπύργου',
        id: '5002',
      },
      {
        value: 'Δυτικής Αττικής$Ελευσίνας',
        title: 'Ελευσίνας',
        key: 'Δυτικής Αττικής$Ελευσίνας',
        id: '5001',
      },
      {
        value: 'Δυτικής Αττικής$Μάνδρας-Ειδυλλίας',
        title: 'Μάνδρας-Ειδυλλίας',
        key: 'Δυτικής Αττικής$Μάνδρας-Ειδυλλίας',
        id: '5003',
      },
      {
        value: 'Δυτικής Αττικής$Μεγαρέων',
        title: 'Μεγαρέων',
        key: 'Δυτικής Αττικής$Μεγαρέων',
        id: '5004',
      },
    ]
  },
  {
    value: 'Δυτικού Τομέα Αθηνών',
    title: 'Δυτικού Τομέα Αθηνών',
    key: 'Δυτικού Τομέα Αθηνών',
    id: '47',
    children: [
      {
        value: 'Δυτικού Τομέα Αθηνών$Αγίας Βαρβάρας',
        title: 'Αγίας Βαρβάρας',
        key: 'Δυτικού Τομέα Αθηνών$Αγίας Βαρβάρας',
        id: '4702',
      },
      {
        value: 'Δυτικού Τομέα Αθηνών$Χαϊδαρίου',
        title: 'Χαϊδαρίου',
        key: 'Δυτικού Τομέα Αθηνών$Χαϊδαρίου',
        id: '4707',
      },
      {
        value: 'Δυτικού Τομέα Αθηνών$Αιγάλεω',
        title: 'Αιγάλεω',
        key: 'Δυτικού Τομέα Αθηνών$Αιγάλεω',
        id: '4704',
      },
      {
        value: 'Δυτικού Τομέα Αθηνών$Περιστερίου',
        title: 'Περιστερίου',
        key: 'Δυτικού Τομέα Αθηνών$Περιστερίου',
        id: '4701',
      },
      {
        value: 'Δυτικού Τομέα Αθηνών$Πετρούπολης',
        title: 'Πετρούπολης',
        key: 'Δυτικού Τομέα Αθηνών$Πετρούπολης',
        id: '4706',
      },
      {
        value: 'Δυτικού Τομέα Αθηνών$Ιλίου',
        title: 'Ιλίου',
        key: 'Δυτικού Τομέα Αθηνών$Ιλίου',
        id: '4705',
      },
      {
        value: 'Δυτικού Τομέα Αθηνών$Αγίων Αναργύρων - Καματερού',
        title: 'Αγίων Αναργύρων - Καματερού',
        key: 'Δυτικού Τομέα Αθηνών$Αγίων Αναργύρων - Καματερού',
        id: '4703',
      },
    ]
  },
  {
    value: 'Ευρυτανίας',
    title: 'Ευρυτανίας',
    key: 'Ευρυτανίας',
    id: '30',
    children: [
      {
        value: 'Ευρυτανίας$Καρπενησίου',
        title: 'Καρπενησίου',
        key: 'Ευρυτανίας$Καρπενησίου',
        id: '3001',
      },
      {
        value: 'Ευρυτανίας$Αγράφων',
        title: 'Αγράφων',
        key: 'Ευρυτανίας$Αγράφων',
        id: '3002'
      },
      ]
  },
  {
    value: 'Εύβοιας',
    title: 'Εύβοιας',
    key: 'Εύβοιας',
    id: '29',
    children: [
      {
        value: 'Εύβοιας$Σκύρου',
        title: 'Σκύρου',
        key: 'Εύβοιας$Σκύρου',
        id: '2908',
      },
      {
        value: 'Εύβοιας$Ιστιαίας - Αιδηψού',
        title: 'Ιστιαίας - Αιδηψού',
        key: 'Εύβοιας$Ιστιαίας - Αιδηψού',
        id: '2904',
      },
      {
        value: 'Εύβοιας$Μαντουδίου-Λίμνης-Αγίας Άννας',
        title: 'Μαντουδίου-Λίμνης-Αγίας Άννας',
        key: 'Εύβοιας$Μαντουδίου-Λίμνης-Αγίας Άννας',
        id: '2907',
      },
      {
        value: 'Εύβοιας$Διρφύων-Μεσσαπίων',
        title: 'Διρφύων-Μεσσαπίων',
        key: 'Εύβοιας$Διρφύων-Μεσσαπίων',
        id: '2902',
      },
      {
        value: 'Εύβοιας$Χαλκιδέων',
        title: 'Χαλκιδέων',
        key: 'Εύβοιας$Χαλκιδέων',
        id: '2901',
      },
      {
        value: 'Εύβοιας$Ερέτριας',
        title: 'Ερέτριας',
        key: 'Εύβοιας$Ερέτριας',
        id: '2903',
      },
      {
        value: 'Εύβοιας$Κύμης-Αλιβερίου',
        title: 'Κύμης-Αλιβερίου',
        key: 'Εύβοιας$Κύμης-Αλιβερίου',
        id: '2906',
      },
      {
        value: 'Εύβοιας$Καρύστου',
        title: 'Καρύστου',
        key: 'Εύβοιας$Καρύστου',
        id: '2905',
      },
    ]
  },
  {
    value: 'Ζακύνθου',
    title: 'Ζακύνθου',
    key: 'Ζακύνθου',
    id: '33',
    children: [
      {
        value: 'Ζακύνθου$Ζακύνθου',
        title: 'Ζακύνθου',
        key: 'Ζακύνθου$Ζακύνθου',
        id: '3301',
      },
      ]
  },
  {
    value: 'Ηλείας',
    title: 'Ηλείας',
    key: 'Ηλείας',
    id: '39',
    children: [
      {
        value: 'Ηλείας$Ανδραβίδας - Κυλλήνης',
        title: 'Ανδραβίδας - Κυλλήνης',
        key: 'Ηλείας$Ανδραβίδας - Κυλλήνης',
        id: '3903',
      },
      {
        value: 'Ηλείας$Πηνειού',
        title: 'Πηνειού',
        key: 'Ηλείας$Πηνειού',
        id: '3907',
      },
      {
        value: 'Ηλείας$Ήλιδας',
        title: 'Ήλιδας',
        key: 'Ηλείας$Ήλιδας',
        id: '3902',
      },
      {
        value: 'Ηλείας$Πύργου',
        title: 'Πύργου',
        key: 'Ηλείας$Πύργου',
        id: '3901',
      },
      {
        value: 'Ηλείας$Αρχαίας Ολυμπίας',
        title: 'Αρχαίας Ολυμπίας',
        key: 'Ηλείας$Αρχαίας Ολυμπίας',
        id: '3905',
      },
      {
        value: 'Ηλείας$Ανδρίτσαινας - Κρεστένων',
        title: 'Ανδρίτσαινας - Κρεστένων',
        key: 'Ηλείας$Ανδρίτσαινας - Κρεστένων',
        id: '3904',
      },
      {
        value: 'Ηλείας$Ζαχάρως',
        title: 'Ζαχάρως',
        key: 'Ηλείας$Ζαχάρως',
        id: '3906',
      },
    ]
  },
  {
    value: 'Ημαθίας',
    title: 'Ημαθίας',
    key: 'Ημαθίας',
    id: '08',
    children: [
      {
        value: 'Ημαθίας$Αλεξάνδρειας',
        title: 'Αλεξάνδρειας',
        key: 'Ημαθίας$Αλεξάνδρειας',
        id: '0802',
      },
      {
        value: 'Ημαθίας$Βέροιας',
        title: 'Βέροιας',
        key: 'Ημαθίας$Βέροιας',
        id: '0801',
      },
      {
        value: 'Ημαθίας$Ηρωικής Πόλεως Νάουσας',
        title: 'Ηρωικής Πόλεως Νάουσας',
        key: 'Ημαθίας$Ηρωικής Πόλεως Νάουσας',
        id: '0803',
      },
    ]
  },
  {
    value: 'Ηρακλείου',
    title: 'Ηρακλείου',
    key: 'Ηρακλείου',
    id: '71',
    children: [
      {
        value: 'Ηρακλείου$Βιάννου',
        title: 'Βιάννου',
        key: 'Ηρακλείου$Βιάννου',
        id: '7103',
      },
      {
        value: 'Ηρακλείου$Μινώα Πεδιάδας',
        title: 'Μινώα Πεδιάδας',
        key: 'Ηρακλείου$Μινώα Πεδιάδας',
        id: '7106',
      },
      {
        value: 'Ηρακλείου$Αρχανών - Αστερουσίων',
        title: 'Αρχανών - Αστερουσίων',
        key: 'Ηρακλείου$Αρχανών - Αστερουσίων',
        id: '7102',
      },
      {
        value: 'Ηρακλείου$Χερσονήσου',
        title: 'Χερσονήσου',
        key: 'Ηρακλείου$Χερσονήσου',
        id: '7108',
      },
      {
        value: 'Ηρακλείου$Ηρακλείου',
        title: 'Ηρακλείου',
        key: 'Ηρακλείου$Ηρακλείου',
        id: '7101',
      },
      {
        value: 'Ηρακλείου$Μαλεβιζίου',
        title: 'Μαλεβιζίου',
        key: 'Ηρακλείου$Μαλεβιζίου',
        id: '7105',
      },
      {
        value: 'Ηρακλείου$Γόρτυνας',
        title: 'Γόρτυνας',
        key: 'Ηρακλείου$Γόρτυνας',
        id: '7104',
      },
      {
        value: 'Ηρακλείου$Φαιστού',
        title: 'Φαιστού',
        key: 'Ηρακλείου$Φαιστού',
        id: '7107',
      },
    ]
  },
  {
    value: 'Θάσου',
    title: 'Θάσου',
    key: 'Θάσου',
    id: '04',
    children: [
      {
        value: 'Θάσου$Θάσου',
        title: 'Θάσου',
        key: 'Θάσου$Θάσου',
        id: '04',
      },
      ]
  },
  {
    value: 'Θήρας',
    title: 'Θήρας',
    key: 'Θήρας',
    id: '60',
    children: [
      {
        value: 'Θήρας$Ιητών',
        title: 'Ιητών',
        key: 'Θήρας$Ιητών',
        id: '6003',
      },
      {
        value: 'Θήρας$Σικίνου',
        title: 'Σικίνου',
        key: 'Θήρας$Σικίνου',
        id: '6004',
      },
      {
        value: 'Θήρας$Φολεγάνδρου',
        title: 'Φολεγάνδρου',
        key: 'Θήρας$Φολεγάνδρου',
        id: '6005',
      },
      {
        value: 'Θήρας$Θήρας',
        title: 'Θήρας',
        key: 'Θήρας$Θήρας',
        id: '6001',
      },
      {
        value: 'Θήρας$Ανάφης',
        title: 'Ανάφης',
        key: 'Θήρας$Ανάφης',
        id: '6002',
      },
    ]
  },
  {
    value: 'Θεσπρωτίας',
    title: 'Θεσπρωτίας',
    key: 'Θεσπρωτίας',
    id: '20',
    children: [
      {
        value: 'Θεσπρωτίας$Σουλίου',
        title: 'Σουλίου',
        key: 'Θεσπρωτίας$Σουλίου',
        id: '2002',
      },
      {
        value: 'Θεσπρωτίας$Φιλιατών',
        title: 'Φιλιατών',
        key: 'Θεσπρωτίας$Φιλιατών',
        id: '2003',
      },
      {
        value: 'Θεσπρωτίας$Ηγουμενίτσας',
        title: 'Ηγουμενίτσας',
        key: 'Θεσπρωτίας$Ηγουμενίτσας',
        id: '2001',
      },
    ]
  },
  {
    value: 'Θεσσαλονίκης',
    title: 'Θεσσαλονίκης',
    key: 'Θεσσαλονίκης',
    id: '07',
    children: [
      {
        value: 'Θεσσαλονίκης$Χαλκηδόνος',
        title: 'Χαλκηδόνος',
        key: 'Θεσσαλονίκης$Χαλκηδόνος',
        id: '0713',
      },
      {
        value: 'Θεσσαλονίκης$Δέλτα',
        title: 'Δέλτα',
        key: 'Θεσσαλονίκης$Δέλτα',
        id: '0704',
      },
      {
        value: 'Θεσσαλονίκης$Ωραιοκάστρου',
        title: 'Ωραιοκάστρου',
        key: 'Θεσσαλονίκης$Ωραιοκάστρου',
        id: '0714',
      },
      {
        value: 'Θεσσαλονίκης$Παύλου Μελά',
        title: 'Παύλου Μελά',
        key: 'Θεσσαλονίκης$Παύλου Μελά',
        id: '0711',
      },
      {
        value: 'Θεσσαλονίκης$Αμπελοκήπων-Μενεμένης',
        title: 'Αμπελοκήπων-Μενεμένης',
        key: 'Θεσσαλονίκης$Αμπελοκήπων-Μενεμένης',
        id: '0702',
      },
      {
        value: 'Θεσσαλονίκης$Κορδελιού-Ευόσμου',
        title: 'Κορδελιού-Ευόσμου',
        key: 'Θεσσαλονίκης$Κορδελιού-Ευόσμου',
        id: '0708',
      },
      {
        value: 'Θεσσαλονίκης$Νεάπολης-Συκεών',
        title: 'Νεάπολης-Συκεών',
        key: 'Θεσσαλονίκης$Νεάπολης-Συκεών',
        id: '0710',
      },
      {
        value: 'Θεσσαλονίκης$Θεσσαλονίκης',
        title: 'Θεσσαλονίκης',
        key: 'Θεσσαλονίκης$Θεσσαλονίκης',
        id: '0715',
      },
      {
        value: 'Θεσσαλονίκης$Καλαμαριάς',
        title: 'Καλαμαριάς',
        key: 'Θεσσαλονίκης$Καλαμαριάς',
        id: '0707',
      },
      {
        value: 'Θεσσαλονίκης$Θερμαϊκού',
        title: 'Θερμαϊκού',
        key: 'Θεσσαλονίκης$Θερμαϊκού',
        id: '0705',
      },
      {
        value: 'Θεσσαλονίκης$Θέρμης',
        title: 'Θέρμης',
        key: 'Θεσσαλονίκης$Θέρμης',
        id: '0706',
      },
      {
        value: 'Θεσσαλονίκης$Πυλαίας-Χορτιάτη',
        title: 'Πυλαίας-Χορτιάτη',
        key: 'Θεσσαλονίκης$Πυλαίας-Χορτιάτη',
        id: '0712',
      },
      {
        value: 'Θεσσαλονίκης$Λαγκαδά',
        title: 'Λαγκαδά',
        key: 'Θεσσαλονίκης$Λαγκαδά',
        id: '0709',
      },
      {
        value: 'Θεσσαλονίκης$Βόλβης',
        title: 'Βόλβης',
        key: 'Θεσσαλονίκης$Βόλβης',
        id: '0703',
      }
    ]
  },
  {
    value: 'Ιθάκης',
    title: 'Ιθάκης',
    key: 'Ιθάκης',
    id: '34',
    children: [
      {
        value: 'Ιθάκης$Ιθάκης',
        title: 'Ιθάκης',
        key: 'Ιθάκης$Ιθάκης',
        id: '3401',
      },
      ]
  },
  {
    value: 'Ικαρίας',
    title: 'Ικαρίας',
    key: 'Ικαρίας',
    id: '54',
    children: [
      {
        value: 'Ικαρίας$Φούρνων Κορσεών',
        title: 'Φούρνων Κορσεών',
        key: 'Ικαρίας$Φούρνων Κορσεών',
        id: '5402',
      },
      {
        value: 'Ικαρίας$Ικαρίας',
        title: 'Ικαρίας',
        key: 'Ικαρίας$Ικαρίας',
        id: '5401',
      },
    ]
  },
  {
    value: 'Ιωαννίνων',
    title: 'Ιωαννίνων',
    key: 'Ιωαννίνων',
    id: '18',
    children: [
      {
        value: 'Ιωαννίνων$Κόνιτσας',
        title: 'Κόνιτσας',
        key: 'Ιωαννίνων$Κόνιτσας',
        id: '1806',
      },
      {
        value: 'Ιωαννίνων$Πωγωνίου',
        title: 'Πωγωνίου',
        key: 'Ιωαννίνων$Πωγωνίου',
        id: '1808',
      },
      {
        value: 'Ιωαννίνων$Ζαγορίου',
        title: 'Ζαγορίου',
        key: 'Ιωαννίνων$Ζαγορίου',
        id: '1804',
      },
      {
        value: 'Ιωαννίνων$Ζίτσας',
        title: 'Ζίτσας',
        key: 'Ιωαννίνων$Ζίτσας',
        id: '1805',
      },
      {
        value: 'Ιωαννίνων$Ιωαννιτών',
        title: 'Ιωαννιτών',
        key: 'Ιωαννίνων$Ιωαννιτών',
        id: '1801',
      },
      {
        value: 'Ιωαννίνων$Μετσόβου',
        title: 'Μετσόβου',
        key: 'Ιωαννίνων$Μετσόβου',
        id: '1807',
      },
      {
        value: 'Ιωαννίνων$Βορείων Τζουμέρκων',
        title: 'Βορείων Τζουμέρκων',
        key: 'Ιωαννίνων$Βορείων Τζουμέρκων',
        id: '1802',
      },
      {
        value: 'Ιωαννίνων$Δωδώνης',
        title: 'Δωδώνης',
        key: 'Ιωαννίνων$Δωδώνης',
        id: '1803',
      },
    ]
  },
  {
	  value: 'Κέρκυρας',
	  title: 'Κέρκυρας',
	  key: 'Κέρκυρας',
    id: '32',
	  children: [
	    {
	      value: 'Κέρκυρας$Κεντρικής Κέρκυρας και Διαποντίων Νήσων',
	      title: 'Κεντρικής Κέρκυρας και Διαποντίων Νήσων',
	      key: 'Κέρκυρας$Κεντρικής Κέρκυρας και Διαποντίων Νήσων',
        id: '3201',
	    },
	    {
	      value: 'Κέρκυρας$Βόρειας Κέρκυρας',
	      title: 'Βόρειας Κέρκυρας',
	      key: 'Κέρκυρας$Βόρειας Κέρκυρας',
        id: '3201',
	    },
	    {
	      value: 'Κέρκυρας$Νότιας Κέρκυρας',
	      title: 'Νότιας Κέρκυρας',
	      key: 'Κέρκυρας$Νότιας Κέρκυρας',
        id: '3201',
	    },
	    {
	      value: 'Κέρκυρας$Παξών',
	      title: 'Παξών',
	      key: 'Κέρκυρας$Παξών',
        id: '3202',
	    },
  	  ]
	},
  {
    value: 'Κέας-Κύθνου',
    title: 'Κέας-Κύθνου',
    key: 'Κέας-Κύθνου',
    id: '63',
    children: [
      {
        value: 'Κέας-Κύθνου$Κέας',
        title: 'Κέας',
        key: 'Κέας-Κύθνου$Κέας',
        id: '6301',
      },
      {
        value: 'Κέας-Κύθνου$Κύθνου',
        title: 'Κύθνου',
        key: 'Κέας-Κύθνου$Κύθνου',
        id: '6302',
      },
      ]
  },
  {
    value: 'Καβάλας',
    title: 'Καβάλας',
    key: 'Καβάλας',
    id: '05',
    children: [
      {
        value: 'Καβάλας$Νέστου',
        title: 'Νέστου',
        key: 'Καβάλας$Νέστου',
        id: '0502',
      },
      {
        value: 'Καβάλας$Καβάλας',
        title: 'Καβάλας',
        key: 'Καβάλας$Καβάλας',
        id: '0501',
      },
      {
        value: 'Καβάλας$Παγγαίου',
        title: 'Παγγαίου',
        key: 'Καβάλας$Παγγαίου',
        id: '0503',
      },
    ]
  },
  {
    value: 'Καλύμνου',
    title: 'Καλύμνου',
    key: 'Καλύμνου',
    id: '61',
    children: [
      {
        value: 'Καλύμνου$Αγαθονησίου',
        title: 'Αγαθονησίου',
        key: 'Καλύμνου$Αγαθονησίου',
        id: '6102',
      },
      {
        value: 'Καλύμνου$Πάτμου',
        title: 'Πάτμου',
        key: 'Καλύμνου$Πάτμου',
        id: '6106',
      },
      {
        value: 'Καλύμνου$Λειψών',
        title: 'Λειψών',
        key: 'Καλύμνου$Λειψών',
        id: '6104',
      },
      {
        value: 'Καλύμνου$Λέρου',
        title: 'Λέρου',
        key: 'Καλύμνου$Λέρου',
        id: '6105',
      },
      {
        value: 'Καλύμνου$Καλυμνίων',
        title: 'Καλυμνίων',
        key: 'Καλύμνου$Καλυμνίων',
        id: '6101',
      },
      {
        value: 'Καλύμνου$Αστυπάλαιας',
        title: 'Αστυπάλαιας',
        key: 'Καλύμνου$Αστυπάλαιας',
        id: '6103',
      },
    ]
  },
  {
    value: 'Καρδίτσας',
    title: 'Καρδίτσας',
    key: 'Καρδίτσας',
    id: '23',
    children: [
      {
        value: 'Καρδίτσας$Παλαμά',
        title: 'Παλαμά',
        key: 'Καρδίτσας$Παλαμά',
        id: '2305',
      },
      {
        value: 'Καρδίτσας$Μουζακίου',
        title: 'Μουζακίου',
        key: 'Καρδίτσας$Μουζακίου',
        id: '2304',
      },
      {
        value: 'Καρδίτσας$Σοφάδων',
        title: 'Σοφάδων',
        key: 'Καρδίτσας$Σοφάδων',
        id: '2306',
      },
      {
        value: 'Καρδίτσας$Αργιθέας',
        title: 'Αργιθέας',
        key: 'Καρδίτσας$Αργιθέας',
        id: '2302',
      },
      {
        value: 'Καρδίτσας$Λίμνης Πλαστήρα',
        title: 'Λίμνης Πλαστήρα',
        key: 'Καρδίτσας$Λίμνης Πλαστήρα',
        id: '2303',
      },
      {
        value: 'Καρδίτσας$Καρδίτσας',
        title: 'Καρδίτσας',
        key: 'Καρδίτσας$Καρδίτσας',
        id: '2301',
      },
    ]
  },
  {
    value: 'Καρπάθου-Ηρωϊκής Νήσου Κάσου',
    title: 'Καρπάθου-Ηρωϊκής Νήσου Κάσου',
    key: 'Καρπάθου-Ηρωϊκής Νήσου Κάσου',
    id: '62',
    children: [
      {
        value: 'Καρπάθου-Ηρωϊκής Νήσου Κάσου$Καρπάθου',
        title: 'Καρπάθου',
        key: 'Καρπάθου-Ηρωϊκής Νήσου Κάσου$Καρπάθου',
        id: '6201',
      },
      {
        value: 'Καρπάθου-Ηρωϊκής Νήσου Κάσου$Ηρωικής Νήσου Κάσου',
        title: 'Ηρωικής Νήσου Κάσου',
        key: 'Καρπάθου-Ηρωϊκής Νήσου Κάσου$Ηρωικής Νήσου Κάσου',
        id: '6202',
      },
    ]
  },
  {
    value: 'Καστοριάς',
    title: 'Καστοριάς',
    key: 'Καστοριάς',
    id: '16',
    children: [
      {
        value: 'Καστοριάς$Καστοριάς',
        title: 'Καστοριάς',
        key: 'Καστοριάς$Καστοριάς',
        id: '1601',
      },
      {
        value: 'Καστοριάς$Νεστόριου',
        title: 'Νεστόριου',
        key: 'Καστοριάς$Νεστόριου',
        id: '1602',
      },
      {
        value: 'Καστοριάς$Άργους Ορεστικού',
        title: 'Άργους Ορεστικού',
        key: 'Καστοριάς$Άργους Ορεστικού',
        id: '1603',
      },
    ]
  },        
  {
    value: 'Κεντρικού Τομέα Αθηνών',
    title: 'Κεντρικού Τομέα Αθηνών',
    key: 'Κεντρικού Τομέα Αθηνών',
    id: '45',
    children: [
      {
        value: 'Κεντρικού Τομέα Αθηνών$Νέας Φιλαδέλφειας - Νέας Χαλκηδόνας',
        title: 'Νέας Φιλαδέλφειας - Νέας Χαλκηδόνας',
        key: 'Κεντρικού Τομέα Αθηνών$Νέας Φιλαδέλφειας - Νέας Χαλκηδόνας',
        id: '4508',
      },
      {
        value: 'Κεντρικού Τομέα Αθηνών$Γαλατσίου',
        title: 'Γαλατσίου',
        key: 'Κεντρικού Τομέα Αθηνών$Γαλατσίου',
        id: '4503',
      },
      {
        value: 'Κεντρικού Τομέα Αθηνών$Αθηναίων',
        title: 'Αθηναίων',
        key: 'Κεντρικού Τομέα Αθηνών$Αθηναίων',
        id: '4501',
      },
      {
        value: 'Κεντρικού Τομέα Αθηνών$Δάφνης - Υμηττού',
        title: 'Δάφνης - Υμηττού',
        key: 'Κεντρικού Τομέα Αθηνών$Δάφνης - Υμηττού',
        id: '4504',
      },
      {
        value: 'Κεντρικού Τομέα Αθηνών$Ηλιούπολης',
        title: 'Ηλιούπολης',
        key: 'Κεντρικού Τομέα Αθηνών$Ηλιούπολης',
        id: '4506',
      },
      {
        value: 'Κεντρικού Τομέα Αθηνών$Βύρωνος',
        title: 'Βύρωνος',
        key: 'Κεντρικού Τομέα Αθηνών$Βύρωνος',
        id: '4502',
      },
      {
        value: 'Κεντρικού Τομέα Αθηνών$Καισαριανής',
        title: 'Καισαριανής',
        key: 'Κεντρικού Τομέα Αθηνών$Καισαριανής',
        id: '4507',
      },
      {
        value: 'Κεντρικού Τομέα Αθηνών$Ζωγράφου',
        title: 'Ζωγράφου',
        key: 'Κεντρικού Τομέα Αθηνών$Ζωγράφου',
        id: '4505',
      },
    ]
  },
  {
	  value: 'Κεφαλληνίας',
	  title: 'Κεφαλληνίας',
	  key: 'Κεφαλληνίας',
    id: '35',
	  children: [
	    {
	      value: 'Κεφαλληνίας$Αργοστολίου',
	      title: 'Αργοστολίου',
	      key: 'Κεφαλληνίας$Αργοστολίου',
        id: '3501',
	    },
	    {
	      value: 'Κεφαλληνίας$Ληξουρίου',
	      title: 'Ληξουρίου',
	      key: 'Κεφαλληνίας$Ληξουρίου',
        id: '3501',
	    },
	    {
	      value: 'Κεφαλληνίας$Σάμης',
	      title: 'Σάμης',
	      key: 'Κεφαλληνίας$Σάμης',
        id: '3501',
	    },
  	  ]
	},
  {
    value: 'Κιλκίς',
    title: 'Κιλκίς',
    key: 'Κιλκίς',
    id: '09',
    children: [
      {
        value: 'Κιλκίς$Κιλκίς',
        title: 'Κιλκίς',
        key: 'Κιλκίς$Κιλκίς',
        id: '0901',
      },
      {
        value: 'Κιλκίς$Παιονίας',
        title: 'Παιονίας',
        key: 'Κιλκίς$Παιονίας',
        id: '0902',
      },
    ]
  },
  {
    value: 'Κοζάνης',
    title: 'Κοζάνης',
    key: 'Κοζάνης',
    id: '14',
    children: [
      {
        value: 'Κοζάνης$Εορδαίας',
        title: 'Εορδαίας',
        key: 'Κοζάνης$Εορδαίας',
        id: '1403',
      },
      {
        value: 'Κοζάνης$Κοζάνης',
        title: 'Κοζάνης',
        key: 'Κοζάνης$Κοζάνης',
        id: '1401',
      },
      {
        value: 'Κοζάνης$Βοΐου',
        title: 'Βοΐου',
        key: 'Κοζάνης$Βοΐου',
        id: '1402',
      },
      {
        value: 'Κοζάνης$Βελβεντού',
        title: 'Βελβεντού',
        key: 'Κοζάνης$Βελβεντού',
        id: '1404',
      },
      {
        value: 'Κοζάνης$Σερβίων',
        title: 'Σερβίων',
        key: 'Κοζάνης$Σερβίων',
        id: '1404',
      },
    ]
  },
  {
    value: 'Κορινθίας',
    title: 'Κορινθίας',
    key: 'Κορινθίας',
    id: '42',
    children: [
      {
        value: 'Κορινθίας$Λουτρακίου-Περαχώρας-Αγίων Θεοδώρων',
        title: 'Λουτρακίου-Περαχώρας-Αγίων Θεοδώρων',
        key: 'Κορινθίας$Λουτρακίου-Περαχώρας-Αγίων Θεοδώρων',
        id: '4203',
      },
      {
        value: 'Κορινθίας$Κορινθίων',
        title: 'Κορινθίων',
        key: 'Κορινθίας$Κορινθίων',
        id: '4201',
      },
      {
        value: 'Κορινθίας$Βέλου-Βόχας',
        title: 'Βέλου-Βόχας',
        key: 'Κορινθίας$Βέλου-Βόχας',
        id: '4202',
      },
      {
        value: 'Κορινθίας$Σικυωνίων',
        title: 'Σικυωνίων',
        key: 'Κορινθίας$Σικυωνίων',
        id: '4206',
      },
      {
        value: 'Κορινθίας$Ξυλοκάστρου-Ευρωστίνης',
        title: 'Ξυλοκάστρου-Ευρωστίνης',
        key: 'Κορινθίας$Ξυλοκάστρου-Ευρωστίνης',
        id: '4205',
      },
      {
        value: 'Κορινθίας$Νεμέας',
        title: 'Νεμέας',
        key: 'Κορινθίας$Νεμέας',
        id: '4204',
      },
    ]
  },
  {
    value: 'Κω',
    title: 'Κω',
    key: 'Κω',
    id: '64',
    children: [
      {
        value: 'Κω$Κω',
        title: 'Κω',
        key: 'Κω$Κω',
        id: '6401',
      },
      {
        value: 'Κω$Νισύρου',
        title: 'Νισύρου',
        key: 'Κω$Νισύρου',
        id: '6402',
      },
    ]
  },
  {
    value: 'Λάρισας',
    title: 'Λάρισας',
    key: 'Λάρισας',
    id: '22',
    children: [
      {
        value: 'Λάρισας$Ελασσόνας',
        title: 'Ελασσόνας',
        key: 'Λάρισας$Ελασσόνας',
        id: '2203',
      },
      {
        value: 'Λάρισας$Τυρνάβου',
        title: 'Τυρνάβου',
        key: 'Λάρισας$Τυρνάβου',
        id: '2206',
      },
      {
        value: 'Λάρισας$Τεμπών',
        title: 'Τεμπών',
        key: 'Λάρισας$Τεμπών',
        id: '2205',
      },
      {
        value: 'Λάρισας$Λάρισας',
        title: 'Λάρισας',
        key: 'Λάρισας$Λάρισας',
        id: '2201',
      },
      {
        value: 'Λάρισας$Αγιάς',
        title: 'Αγιάς',
        key: 'Λάρισας$Αγιάς',
        id: '2202',
      },
      {
        value: 'Λάρισας$Κιλελέρ',
        title: 'Κιλελέρ',
        key: 'Λάρισας$Κιλελέρ',
        id: '2204',
      },
      {
        value: 'Λάρισας$Φαρσάλων',
        title: 'Φαρσάλων',
        key: 'Λάρισας$Φαρσάλων',
        id: '2207',
      },
    ]
  },
  {
	  value: 'Λέσβου',
	  title: 'Λέσβου',
	  key: 'Λέσβου',
    id: '53',
	  children: [
	    {
	      value: 'Λέσβου$Δυτικής Λέσβου',
	      title: 'Δυτικής Λέσβου',
	      key: 'Λέσβου$Δυτικής Λέσβου',
        id: '5301',
	    },
	    {
	      value: 'Λέσβου$Μυτιλήνης',
	      title: 'Μυτιλήνης',
	      key: 'Λέσβου$Μυτιλήνης',
        id: '5301',
	    },
  	  ]
	},
  {
    value: 'Λήμνου',
    title: 'Λήμνου',
    key: 'Λήμνου',
    id: '55',
    children: [
      {
        value: 'Λήμνου$Λήμνου',
        title: 'Λήμνου',
        key: 'Λήμνου$Λήμνου',
        id: '5501',
      },
      {
        value: 'Λήμνου$Αγίου Ευστρατίου',
        title: 'Αγίου Ευστρατίου',
        key: 'Λήμνου$Αγίου Ευστρατίου',
        id: '5502',
      },
    ]
  },
  {
    value: 'Λακωνίας',
    title: 'Λακωνίας',
    key: 'Λακωνίας',
    id: '43',
    children: [
      {
        value: 'Λακωνίας$Ανατολικής Μάνης',
        title: 'Ανατολικής Μάνης',
        key: 'Λακωνίας$Ανατολικής Μάνης',
        id: '4302',
      },
      {
        value: 'Λακωνίας$Σπάρτης',
        title: 'Σπάρτης',
        key: 'Λακωνίας$Σπάρτης',
        id: '4301',
      },
      {
        value: 'Λακωνίας$Ευρώτα',
        title: 'Ευρώτα',
        key: 'Λακωνίας$Ευρώτα',
        id: '4304',
      },
      {
        value: 'Λακωνίας$Μονεμβασιάς',
        title: 'Μονεμβασιάς',
        key: 'Λακωνίας$Μονεμβασιάς',
        id: '4305',
      },
      {
        value: 'Λακωνίας$Ελαφονήσου',
        title: 'Ελαφονήσου',
        key: 'Λακωνίας$Ελαφονήσου',
        id: '4303',
      },
    ]
  },
  {
    value: 'Λασιθίου',
    title: 'Λασιθίου',
    key: 'Λασιθίου',
    id: '72',
    children: [
      {
        value: 'Λασιθίου$Σητείας',
        title: 'Σητείας',
        key: 'Λασιθίου$Σητείας',
        id: '7204',
      },
      {
        value: 'Λασιθίου$Ιεράπετρας',
        title: 'Ιεράπετρας',
        key: 'Λασιθίου$Ιεράπετρας',
        id: '7202',
      },
      {
        value: 'Λασιθίου$Αγίου Νικολάου',
        title: 'Αγίου Νικολάου',
        key: 'Λασιθίου$Αγίου Νικολάου',
        id: '7201',
      },
      {
        value: 'Λασιθίου$Οροπεδίου Λασιθίου',
        title: 'Οροπεδίου Λασιθίου',
        key: 'Λασιθίου$Οροπεδίου Λασιθίου',
        id: '7203',
      },
    ]
  },
  {
    value: 'Λευκάδας',
    title: 'Λευκάδας',
    key: 'Λευκάδας',
    id: '36',
    children: [
      {
        value: 'Λευκάδας$Λευκάδας',
        title: 'Λευκάδας',
        key: 'Λευκάδας$Λευκάδας',
        id: '3601',
      },
      {
        value: 'Λευκάδας$Μεγανησίου',
        title: 'Μεγανησίου',
        key: 'Λευκάδας$Μεγανησίου',
        id: '3602',
      },
    ]
  },
  {
    value: 'Μήλου',
    title: 'Μήλου',
    key: 'Μήλου',
    id: '65',
    children: [
      {
        value: 'Μήλου$Σερίφου',
        title: 'Σερίφου',
        key: 'Μήλου$Σερίφου',
        id: '6503',
      },
      {
        value: 'Μήλου$Σίφνου',
        title: 'Σίφνου',
        key: 'Μήλου$Σίφνου',
        id: '6504',
      },
      {
        value: 'Μήλου$Κιμώλου',
        title: 'Κιμώλου',
        key: 'Μήλου$Κιμώλου',
        id: '6502',
      },
      {
        value: 'Μήλου$Μήλου',
        title: 'Μήλου',
        key: 'Μήλου$Μήλου',
        id: '6501',
      },
    ]
  },
  {
    value: 'Μαγνησίας',
    title: 'Μαγνησίας',
    key: 'Μαγνησίας',
    id: '24',
    children: [
      {
        value: 'Μαγνησίας$Ρήγα Φεραίου',
        title: 'Ρήγα Φεραίου',
        key: 'Μαγνησίας$Ρήγα Φεραίου',
        id: '2405',
      },
      {
        value: 'Μαγνησίας$Αλμυρού',
        title: 'Αλμυρού',
        key: 'Μαγνησίας$Αλμυρού',
        id: '2402',
      },
      {
        value: 'Μαγνησίας$Βόλου',
        title: 'Βόλου',
        key: 'Μαγνησίας$Βόλου',
        id: '2401',
      },
      {
        value: 'Μαγνησίας$Ζαγοράς-Μουρεσίου',
        title: 'Ζαγοράς-Μουρεσίου',
        key: 'Μαγνησίας$Ζαγοράς-Μουρεσίου',
        id: '2403',
      },
      {
        value: 'Μαγνησίας$Νοτίου Πηλίου',
        title: 'Νοτίου Πηλίου',
        key: 'Μαγνησίας$Νοτίου Πηλίου',
        id: '2404',
      },
    ]
  },
  {
    value: 'Μεσσηνίας',
    title: 'Μεσσηνίας',
    key: 'Μεσσηνίας',
    id: '44',
    children: [
      {
        value: 'Μεσσηνίας$Οιχαλίας',
        title: 'Οιχαλίας',
        key: 'Μεσσηνίας$Οιχαλίας',
        id: '4404',
      },
      {
        value: 'Μεσσηνίας$Τριφυλίας',
        title: 'Τριφυλίας',
        key: 'Μεσσηνίας$Τριφυλίας',
        id: '4406',
      },
      {
        value: 'Μεσσηνίας$Πύλου - Νέστορος',
        title: 'Πύλου - Νέστορος',
        key: 'Μεσσηνίας$Πύλου - Νέστορος',
        id: '4405',
      },
      {
        value: 'Μεσσηνίας$Μεσσήνης',
        title: 'Μεσσήνης',
        key: 'Μεσσηνίας$Μεσσήνης',
        id: '4403',
      },
      {
        value: 'Μεσσηνίας$Καλαμάτας',
        title: 'Καλαμάτας',
        key: 'Μεσσηνίας$Καλαμάτας',
        id: '4401',
      },
      {
        value: 'Μεσσηνίας$Δυτικής Μάνης',
        title: 'Δυτικής Μάνης',
        key: 'Μεσσηνίας$Δυτικής Μάνης',
        id: '4402',
      },
    ]
  },
  {
    value: 'Μυκόνου',
    title: 'Μυκόνου',
    key: 'Μυκόνου',
    id: '66',
    children: [
      {
        value: 'Μυκόνου$Μυκόνου',
        title: 'Μυκόνου',
        key: 'Μυκόνου$Μυκόνου',
        id: '6601',
      },
      ]
  }, 
  {
    value: 'Νάξου',
    title: 'Νάξου',
    key: 'Νάξου',
    id: '67',
    children: [
      {
        value: 'Νάξου$Νάξου & Μικρών Κυκλάδων',
        title: 'Νάξου & Μικρών Κυκλάδων',
        key: 'Νάξου$Νάξου & Μικρών Κυκλάδων',
        id: '6702',
      },
      {
        value: 'Νάξου$Αμοργού',
        title: 'Αμοργού',
        key: 'Νάξου$Αμοργού',
        id: '6701',
      },
    ]
  },
  {
    value: 'Νήσων Αττικής',
    title: 'Νήσων Αττικής',
    key: 'Νήσων Αττικής',
    id: '52',
    children: [
      {
        value: 'Νήσων Αττικής$Κυθήρων',
        title: 'Κυθήρων',
        key: 'Νήσων Αττικής$Κυθήρων',
        id: '5205',
      },
      {
        value: 'Νήσων Αττικής$Σπετσών',
        title: 'Σπετσών',
        key: 'Νήσων Αττικής$Σπετσών',
        id: '5207',
      },
      {
        value: 'Νήσων Αττικής$Ύδρας',
        title: 'Ύδρας',
        key: 'Νήσων Αττικής$Ύδρας',
        id: '5202',
      },
      {
        value: 'Νήσων Αττικής$Τροιζηνίας - Μεθάνων',
        title: 'Τροιζηνίας - Μεθάνων',
        key: 'Νήσων Αττικής$Τροιζηνίας - Μεθάνων',
        id: '5208',
      },
      {
        value: 'Νήσων Αττικής$Πόρου',
        title: 'Πόρου',
        key: 'Νήσων Αττικής$Πόρου',
        id: '5206',
      },
      {
        value: 'Νήσων Αττικής$Αγκιστρίου',
        title: 'Αγκιστρίου',
        key: 'Νήσων Αττικής$Αγκιστρίου',
        id: '5203',
      },
      {
        value: 'Νήσων Αττικής$Αίγινας',
        title: 'Αίγινας',
        key: 'Νήσων Αττικής$Αίγινας',
        id: '5204',
      },
      {
        value: 'Νήσων Αττικής$Σαλαμίνας',
        title: 'Σαλαμίνας',
        key: 'Νήσων Αττικής$Σαλαμίνας',
        id: '5201',
      },
    ]
  },
  {
    value: 'Νοτίου Τομέα Αθηνών',
    title: 'Νοτίου Τομέα Αθηνών',
    key: 'Νοτίου Τομέα Αθηνών',
    id: '48',
    children: [
      {
        value: 'Νοτίου Τομέα Αθηνών$Μοσχάτου-Ταύρου',
        title: 'Μοσχάτου-Ταύρου',
        key: 'Νοτίου Τομέα Αθηνών$Μοσχάτου-Ταύρου',
        id: '4806',
      },
      {
        value: 'Νοτίου Τομέα Αθηνών$Καλλιθέας',
        title: 'Καλλιθέας',
        key: 'Νοτίου Τομέα Αθηνών$Καλλιθέας',
        id: '4801',
      },
      {
        value: 'Νοτίου Τομέα Αθηνών$Νέας Σμύρνης',
        title: 'Νέας Σμύρνης',
        key: 'Νοτίου Τομέα Αθηνών$Νέας Σμύρνης',
        id: '4807',
      },
      {
        value: 'Νοτίου Τομέα Αθηνών$Παλαιού Φαλήρου',
        title: 'Παλαιού Φαλήρου',
        key: 'Νοτίου Τομέα Αθηνών$Παλαιού Φαλήρου',
        id: '4808',
      },
      {
        value: 'Νοτίου Τομέα Αθηνών$Αγίου Δημητρίου',
        title: 'Αγίου Δημητρίου',
        key: 'Νοτίου Τομέα Αθηνών$Αγίου Δημητρίου',
        id: '4802',
      },
      {
        value: 'Νοτίου Τομέα Αθηνών$Αλίμου',
        title: 'Αλίμου',
        key: 'Νοτίου Τομέα Αθηνών$Αλίμου',
        id: '4803',
      },
      {
        value: 'Νοτίου Τομέα Αθηνών$Ελληνικού-Αργυρούπολης',
        title: 'Ελληνικού-Αργυρούπολης',
        key: 'Νοτίου Τομέα Αθηνών$Ελληνικού-Αργυρούπολης',
        id: '4805',
      },
      {
        value: 'Νοτίου Τομέα Αθηνών$Γλυφάδας',
        title: 'Γλυφάδας',
        key: 'Νοτίου Τομέα Αθηνών$Γλυφάδας',
        id: '4804',
      },
    ]
  },
  {
    value: 'Ξάνθης',
    title: 'Ξάνθης',
    key: 'Ξάνθης',
    id: '06',
    children: [
      {
        value: 'Ξάνθης$Αβδήρων',
        title: 'Αβδήρων',
        key: 'Ξάνθης$Αβδήρων',
        id: '0602',
      },
      {
        value: 'Ξάνθης$Μύκης',
        title: 'Μύκης',
        key: 'Ξάνθης$Μύκης',
        id: '0603',
      },
      {
        value: 'Ξάνθης$Ξάνθης',
        title: 'Ξάνθης',
        key: 'Ξάνθης$Ξάνθης',
        id: '0601',
      },
      {
        value: 'Ξάνθης$Τοπείρου',
        title: 'Τοπείρου',
        key: 'Ξάνθης$Τοπείρου',
        id: '0604',
      },
    ]
  },
  {
    value: 'Πάρου',
    title: 'Πάρου',
    key: 'Πάρου',
    id: '68',
    children: [
      {
        value: 'Πάρου$Αντιπάρου',
        title: 'Αντιπάρου',
        key: 'Πάρου$Αντιπάρου',
        id: '6802',
      },
      {
        value: 'Πάρου$Πάρου',
        title: 'Πάρου',
        key: 'Πάρου$Πάρου',
        id: '6801',
      },
    ]
  },
  {
    value: 'Πέλλας',
    title: 'Πέλλας',
    key: 'Πέλλας',
    id: '10',
    children: [
      {
        value: 'Πέλλας$Πέλλας',
        title: 'Πέλλας',
        key: 'Πέλλας$Πέλλας',
        id: '1003',
      },
      {
        value: 'Πέλλας$Σκύδρας',
        title: 'Σκύδρας',
        key: 'Πέλλας$Σκύδρας',
        id: '1004',
      },
      {
        value: 'Πέλλας$Έδεσσας',
        title: 'Έδεσσας',
        key: 'Πέλλας$Έδεσσας',
        id: '1001',
      },
      {
        value: 'Πέλλας$Αλμωπίας',
        title: 'Αλμωπίας',
        key: 'Πέλλας$Αλμωπίας',
        id: '1002',
      },
    ]
  },
  {
    value: 'Πειραιά',
    title: 'Πειραιά',
    key: 'Πειραιά',
    id: '51',
    children: [
      {
        value: 'Πειραιά$Περάματος',
        title: 'Περάματος',
        key: 'Πειραιά$Περάματος',
        id: '5105',
      },
      {
        value: 'Πειραιά$Κερατσινίου - Δραπετσώνας',
        title: 'Κερατσινίου - Δραπετσώνας',
        key: 'Πειραιά$Κερατσινίου - Δραπετσώνας',
        id: '5102',
      },
      {
        value: 'Πειραιά$Πειραιά',
        title: 'Πειραιά',
        key: 'Πειραιά$Πειραιά',
        id: '5101',
      },
      {
        value: 'Πειραιά$Νίκαιας - Αγίου Ιωάννη Ρέντη',
        title: 'Νίκαιας - Αγίου Ιωάννη Ρέντη',
        key: 'Πειραιά$Νίκαιας - Αγίου Ιωάννη Ρέντη',
        id: '5104',
      },
      {
        value: 'Πειραιά$Κορυδαλλού',
        title: 'Κορυδαλλού',
        key: 'Πειραιά$Κορυδαλλού',
        id: '5103',
      },
    ]
  },
  {
    value: 'Πιερίας',
    title: 'Πιερίας',
    key: 'Πιερίας',
    id: '11',
    children: [
      {
        value: 'Πιερίας$Δίου-Ολύμπου',
        title: 'Δίου-Ολύμπου',
        key: 'Πιερίας$Δίου-Ολύμπου',
        id: '1102',
      },
      {
        value: 'Πιερίας$Κατερίνης',
        title: 'Κατερίνης',
        key: 'Πιερίας$Κατερίνης',
        id: '1101',
      },
      {
        value: 'Πιερίας$Πύδνας-Κολινδρού',
        title: 'Πύδνας-Κολινδρού',
        key: 'Πιερίας$Πύδνας-Κολινδρού',
        id: '1103',
      },
    ]
},
{
    value: 'Πρέβεζας',
    title: 'Πρέβεζας',
    key: 'Πρέβεζας',
    id: '21',
    children: [
      {
        value: 'Πρέβεζας$Πάργας',
        title: 'Πάργας',
        key: 'Πρέβεζας$Πάργας',
        id: '2103',
      },
      {
        value: 'Πρέβεζας$Πρέβεζας',
        title: 'Πρέβεζας',
        key: 'Πρέβεζας$Πρέβεζας',
        id: '2101',
      },
      {
        value: 'Πρέβεζας$Ζηρού',
        title: 'Ζηρού',
        key: 'Πρέβεζας$Ζηρού',
        id: '2102',
      },
    ]
  },
  {
    value: 'Ρεθύμνης',
    title: 'Ρεθύμνης',
    key: 'Ρεθύμνης',
    id: '73',
    children: [
      {
        value: 'Ρεθύμνης$Ανωγείων',
        title: 'Ανωγείων',
        key: 'Ρεθύμνης$Ανωγείων',
        id: '7304',
      },
      {
        value: 'Ρεθύμνης$Μυλοποτάμου',
        title: 'Μυλοποτάμου',
        key: 'Ρεθύμνης$Μυλοποτάμου',
        id: '7305',
      },
      {
        value: 'Ρεθύμνης$Αμαρίου',
        title: 'Αμαρίου',
        key: 'Ρεθύμνης$Αμαρίου',
        id: '7303',
      },
      {
        value: 'Ρεθύμνης$Αγίου Βασιλείου',
        title: 'Αγίου Βασιλείου',
        key: 'Ρεθύμνης$Αγίου Βασιλείου',
        id: '7302',
      },
      {
        value: 'Ρεθύμνης$Ρεθύμνης',
        title: 'Ρεθύμνης',
        key: 'Ρεθύμνης$Ρεθύμνης',
        id: '7301',
      },
    ]
  },
  {
    value: 'Ροδόπης',
    title: 'Ροδόπης',
    key: 'Ροδόπης',
    id: '01',
    children: [
      {
        value: 'Ροδόπης$Αρριανών',
        title: 'Αρριανών',
        key: 'Ροδόπης$Αρριανών',
        id: '0102',
      },
      {
        value: 'Ροδόπης$Μαρωνείας-Σαπών',
        title: 'Μαρωνείας-Σαπών',
        key: 'Ροδόπης$Μαρωνείας-Σαπών',
        id: '0104',
      },
      {
        value: 'Ροδόπης$Κομοτηνής',
        title: 'Κομοτηνής',
        key: 'Ροδόπης$Κομοτηνής',
        id: '0101',
      },
      {
        value: 'Ροδόπης$Ιάσμου',
        title: 'Ιάσμου',
        key: 'Ροδόπης$Ιάσμου',
        id: '0103',
      },
    ]
  },
  {
    value: 'Ρόδου',
    title: 'Ρόδου',
    key: 'Ρόδου',
    id: '69',
    children: [
      {
        value: 'Ρόδου$Τήλου',
        title: 'Τήλου',
        key: 'Ρόδου$Τήλου',
        id: '6904',
      },
      {
        value: 'Ρόδου$Σύμης',
        title: 'Σύμης',
        key: 'Ρόδου$Σύμης',
        id: '6903',
      },
      {
        value: 'Ρόδου$Ρόδου',
        title: 'Ρόδου',
        key: 'Ρόδου$Ρόδου',
        id: '6901',
      },
      {
        value: 'Ρόδου$Χάλκης',
        title: 'Χάλκης',
        key: 'Ρόδου$Χάλκης',
        id: '6905',
      },
      {
        value: 'Ρόδου$Μεγίστης',
        title: 'Μεγίστης',
        key: 'Ρόδου$Μεγίστης',
        id: '6902',
      },
    ]
  },
  {
	  value: 'Σάμου',
	  title: 'Σάμου',
	  key: 'Σάμου',
    id: '56',
	  children: [
	    {
	      value: 'Σάμου$Ανατολικής Σάμου',
	      title: 'Ανατολικής Σάμου',
	      key: 'Σάμου$Ανατολικής Σάμου',
        id: '5601',
	    },
	    {
	      value: 'Σάμου$Δυτικής Σάμου',
	      title: 'Δυτικής Σάμου',
	      key: 'Σάμου$Δυτικής Σάμου',
        id: '5601',
	    },
  	  ]
	},
  {
    value: 'Σερρών',
    title: 'Σερρών',
    key: 'Σερρών',
    id: '12',
    children: [
      {
        value: 'Σερρών$Νέας Ζίχνης',
        title: 'Νέας Ζίχνης',
        key: 'Σερρών$Νέας Ζίχνης',
        id: '1206',
      },
      {
        value: 'Σερρών$Αμφίπολης',
        title: 'Αμφίπολης',
        key: 'Σερρών$Αμφίπολης',
        id: '1202',
      },
      {
        value: 'Σερρών$Βισαλτίας',
        title: 'Βισαλτίας',
        key: 'Σερρών$Βισαλτίας',
        id: '1203',
      },
      {
        value: 'Σερρών$Εμμανουήλ Παππά',
        title: 'Εμμανουήλ Παππά',
        key: 'Σερρών$Εμμανουήλ Παππά',
        id: '1204',
      },
      {
        value: 'Σερρών$Σερρών',
        title: 'Σερρών',
        key: 'Σερρών$Σερρών',
        id: '1201',
      },
      {
        value: 'Σερρών$Ηράκλειας',
        title: 'Ηράκλειας',
        key: 'Σερρών$Ηράκλειας',
        id: '1205',
      },
      {
        value: 'Σερρών$Σιντικής',
        title: 'Σιντικής',
        key: 'Σερρών$Σιντικής',
        id: '1207',
      },
    ]
  },
  {
    value: 'Σποράδων',
    title: 'Σποράδων',
    key: 'Σποράδων',
    id: '25',
    children: [
      {
        value: 'Σποράδων$Σκιάθου',
        title: 'Σκιάθου',
        key: 'Σποράδων$Σκιάθου',
        id: '2501',
      },
      {
        value: 'Σποράδων$Σκοπέλου',
        title: 'Σκοπέλου',
        key: 'Σποράδων$Σκοπέλου',
        id: '2503',
      },
      {
        value: 'Σποράδων$Αλοννήσου',
        title: 'Αλοννήσου',
        key: 'Σποράδων$Αλοννήσου',
        id: '2502',
      },
    ]
  },      
  {
    value: 'Σύρου',
    title: 'Σύρου',
    key: 'Σύρου',
    id: '58',
    children: [
      {
        value: 'Σύρου$Σύρου - Ερμούπολης',
        title: 'Σύρου - Ερμούπολης',
        key: 'Σύρου$Σύρου - Ερμούπολης',
        id: '5801',
      },
    ]
  },
  {
    value: 'Τήνου',
    title: 'Τήνου',
    key: 'Τήνου',
    id: '70',
    children: [
      {
        value: 'Τήνου$Τήνου',
        title: 'Τήνου',
        key: 'Τήνου$Τήνου',
        id: '7001',
      },
      ]
  },
  {
    value: 'Τρικάλων',
    title: 'Τρικάλων',
    key: 'Τρικάλων',
    id: '26',
    children: [
      {
        value: 'Τρικάλων$Πύλης',
        title: 'Πύλης',
        key: 'Τρικάλων$Πύλης',
        id: '2603',
      },
      {
        value: 'Τρικάλων$Μετεώρων',
        title: 'Μετεώρων',
        key: 'Τρικάλων$Μετεώρων',
        id: '2605',
      },
      {
        value: 'Τρικάλων$Τρικκαίων',
        title: 'Τρικκαίων',
        key: 'Τρικάλων$Τρικκαίων',
        id: '2601',
      },
      {
        value: 'Τρικάλων$Φαρκαδόνος',
        title: 'Φαρκαδόνος',
        key: 'Τρικάλων$Φαρκαδόνος',
        id: '2604',
      },
    ]
  },
  {
    value: 'Φθιώτιδας',
    title: 'Φθιώτιδας',
    key: 'Φθιώτιδας',
    id: '27',
    children: [
      {
        value: 'Φθιώτιδας$Λοκρών',
        title: 'Λοκρών',
        key: 'Φθιώτιδας$Λοκρών',
        id: '2704',
      },
      {
        value: 'Φθιώτιδας$Αμφίκλειας-Ελάτειας',
        title: 'Αμφίκλειας-Ελάτειας',
        key: 'Φθιώτιδας$Αμφίκλειας-Ελάτειας',
        id: '2702',
      },
      {
        value: 'Φθιώτιδας$Καμένων Βούρλων',
        title: 'Καμένων Βούρλων',
        key: 'Φθιώτιδας$Καμένων Βούρλων',
        id: '2710',
      },
      {
        value: 'Φθιώτιδας$Λαμιέων',
        title: 'Λαμιέων',
        key: 'Φθιώτιδας$Λαμιέων',
        id: '2701',
      },
      {
        value: 'Φθιώτιδας$Στυλίδας',
        title: 'Στυλίδας',
        key: 'Φθιώτιδας$Στυλίδας',
        id: '2707',
      },
      {
        value: 'Φθιώτιδας$Μακρακώμης',
        title: 'Μακρακώμης',
        key: 'Φθιώτιδας$Μακρακώμης',
        id: '2705',
      },
      {
        value: 'Φθιώτιδας$Δομοκού',
        title: 'Δομοκού',
        key: 'Φθιώτιδας$Δομοκού',
        id: '2703',
      },
    ]
  },
  {
    value: 'Φλώρινας',
    title: 'Φλώρινας',
    key: 'Φλώρινας',
    id: '17',
    children: [
      {
        value: 'Φλώρινας$Αμυνταίου',
        title: 'Αμυνταίου',
        key: 'Φλώρινας$Αμυνταίου',
        id: '1702',
      },
      {
        value: 'Φλώρινας$Φλώρινας',
        title: 'Φλώρινας',
        key: 'Φλώρινας$Φλώρινας',
        id: '1701',
      },
      {
        value: 'Φλώρινας$Πρεσπών',
        title: 'Πρεσπών',
        key: 'Φλώρινας$Πρεσπών',
        id: '1703',
      },
    ]
  },
  {
    value: 'Φωκίδας',
    title: 'Φωκίδας',
    key: 'Φωκίδας',
    id: '31',
    children: [
      {
        value: 'Φωκίδας$Δελφών',
        title: 'Δελφών',
        key: 'Φωκίδας$Δελφών',
        id: '3101',
      },
      {
        value: 'Φωκίδας$Δωρίδος',
        title: 'Δωρίδος',
        key: 'Φωκίδας$Δωρίδος',
        id: '3102',
      },
    ]
  },
  {
    value: 'Χίου',
    title: 'Χίου',
    key: 'Χίου',
    id: '57',
    children: [
      {
        value: 'Χίου$Ηρωικής Νήσου Ψαρών',
        title: 'Ηρωικής Νήσου Ψαρών',
        key: 'Χίου$Ηρωικής Νήσου Ψαρών',
        id: '5703',
      },
      {
        value: 'Χίου$Οινουσσών',
        title: 'Οινουσσών',
        key: 'Χίου$Οινουσσών',
        id: '5702',
      },
      {
        value: 'Χίου$Χίου',
        title: 'Χίου',
        key: 'Χίου$Χίου',
        id: '5701',
      },
    ]
  },
  {
    value: 'Χαλκιδικής',
    title: 'Χαλκιδικής',
    key: 'Χαλκιδικής',
    id: '13',
    children: [
      {
        value: 'Χαλκιδικής$Αριστοτέλη',
        title: 'Αριστοτέλη',
        key: 'Χαλκιδικής$Αριστοτέλη',
        id: '1302',
      },
      {
        value: 'Χαλκιδικής$Σιθωνίας',
        title: 'Σιθωνίας',
        key: 'Χαλκιδικής$Σιθωνίας',
        id: '1305',
      },
      {
        value: 'Χαλκιδικής$Πολύγυρου',
        title: 'Πολύγυρου',
        key: 'Χαλκιδικής$Πολύγυρου',
        id: '1301',
      },
      {
        value: 'Χαλκιδικής$Κασσάνδρας',
        title: 'Κασσάνδρας',
        key: 'Χαλκιδικής$Κασσάνδρας',
        id: '1303',
      },
      {
        value: 'Χαλκιδικής$Νέας Προποντίδας',
        title: 'Νέας Προποντίδας',
        key: 'Χαλκιδικής$Νέας Προποντίδας',
        id: '1304',
      },
    ]
  },
  {
    value: 'Χανίων',
    title: 'Χανίων',
    key: 'Χανίων',
    id: '74',
    children: [
      {
        value: 'Χανίων$Αποκορώνου',
        title: 'Αποκορώνου',
        key: 'Χανίων$Αποκορώνου',
        id: '7402',
      },
      {
        value: 'Χανίων$Χανίων',
        title: 'Χανίων',
        key: 'Χανίων$Χανίων',
        id: '7401',
      },
      {
        value: 'Χανίων$Πλατανιά',
        title: 'Πλατανιά',
        key: 'Χανίων$Πλατανιά',
        id: '7406',
      },
      {
        value: 'Χανίων$Κισσάμου',
        title: 'Κισσάμου',
        key: 'Χανίων$Κισσάμου',
        id: '7405',
      },
      {
        value: 'Χανίων$Καντάνου - Σελίνου',
        title: 'Καντάνου - Σελίνου',
        key: 'Χανίων$Καντάνου - Σελίνου',
        id: '7404',
      },
      {
        value: 'Χανίων$Σφακίων',
        title: 'Σφακίων',
        key: 'Χανίων$Σφακίων',
        id: '7407',
      },
      {
        value: 'Χανίων$Γαύδου',
        title: 'Γαύδου',
        key: 'Χανίων$Γαύδου',
        id: '7403',
      },
    ]
  }      
]
