

class AppTrackService {




    config = {  
      mode: 'cors',
      method: "POST",
      credentials: "include",
      headers: {
        Authorization: `Basic flowable:test`,
        "X-gobeyond-csrf": "gobeyond",
        //'Content-Type':'application/json'
      },
    };
  
      commentBody(msg){
          return {
          "message":msg
          }
      }
      
async getSmrData (address1,address2) {
const token = 'test/test';
 const response = await fetch(`https://installers.smartreadiness.gov.gr/go-beyond-smr/api/1.0/installers/findByArea?area=${address1}&municipality=${address2}`, {
      mode: 'cors',
      method: "GET",
      headers: {
        // Authorization: `Bearer: ${token}`,
        "Content-type": `application/json`,
        "X-gobeyond-csrf": "gobeyond",
        'Origin':'https://installers.smartreadiness.gov.gr',
        'Referer' : 'https://installers.smartreadiness.gov.gr',
        'Access-Control-Allow-Origin':'https://installers.smartreadiness.gov.gr'
      },
    }).then(function(response) {
      console.log("Response has 1 : ",  response);
      return response.json();
    }).then(function(data) {
      console.log("response is : ",data);
      return data;
    });
    console.log("Response has : ",  response.results);
  return response.results;
}




}



export default new AppTrackService();