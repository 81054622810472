import React from 'react';
import { Layout } from 'antd';
import { ReactComponent as Ellada20Dark } from 'assets/icons/ellada_2_0_dark.svg';
import { ReactComponent as Ellada20 } from 'assets/icons/ellada2.0_alone.svg';
import { ReactComponent as EE } from 'assets/icons/EE_alone.svg';
import { ReactComponent as DigitalLogo } from 'assets/icons/ministry-digital-icon-new.svg';
import { ReactComponent as FinanceLogo } from 'assets/icons/ministry-finance-icon-new.svg';
import { ReactComponent as EnvironmentLogo } from 'assets/icons/ministry-environment-icon-new.svg';
// import { ReactComponent as AadeLogo } from 'assets/icons/logo_aade.gov.svg';
import { ReactComponent as KtpLogo } from 'assets/icons/KtP-compact-icon.svg';

const { Content } = Layout;

const Footer = () => {
  return (
    <Content className="footer">
      <div className="footer-logo">
        <Ellada20
          className="digital_logo"
          onClick={() => window.open('https://greece20.gov.gr/', '_blank')}
        />
      </div>
      <div className="footer-logo">
        <EE
          className="digital_logo"
          // onClick={() => window.open('https://greece20.gov.gr/', '_blank')}
        />
      </div>
      <div id="environment-ministry-logo" className="footer-logo">
        <EnvironmentLogo className="digital_logo" 
        onClick={() => window.open('https://ypen.gov.gr/', '_blank')}
        />
      </div>
      <div className="footer-logo">
        <FinanceLogo className="digital_logo" 
        onClick={() => window.open('https://minfin.gov.gr/', '_blank')}
        />
      </div>
      <div className="footer-logo">
        <DigitalLogo
          className="digital_logo"
          onClick={() => window.open('https://mindigital.gr/', '_blank')}
        />
      </div>
      <div className="footer-logo">
        <KtpLogo
          className="ktp_logo"
          onClick={() => window.open('https://www.ktpae.gr/', '_blank')}
        />
      </div>
    </Content>
  );
};

export default Footer;
