import React from 'react';
import { Layout } from 'antd';
import { ReactComponent as Digital } from 'assets/icons/logo_digital.gov.svg';
import { ReactComponent as KtpLogo } from 'assets/icons/logo_ktp.gov.svg';

const { Header } = Layout;

const SubHeaderLanding = () => {
  return (
    <Header className="app-layout__sub_header_landing">
      <div className='header-left'>
        <div className="contents-icons">
          <Digital className='app-layout__logo-digital'
          onClick={() => (window.open('https://mindigital.gr/', '_blank', "noreferrer"))}
          />
        </div>
        <div className="contents-icons">
          <KtpLogo className="app-layout__logo-ktp"
          onClick={() => (window.open('https://www.ktpae.gr/', '_blank', "noreferrer"))} 
          />
        </div>
      </div>
      <div className='header-right'>
        <div className='title'>
          <span>Smart Readiness </span>
        </div>
      </div>
    </Header>
  );
};

export default SubHeaderLanding;
